<template>
  <div class="app-container user-container">
    <div class="total mbm">
      <el-tooltip
        content="除了『已取消』外的申请单使馆费总额"
      >
        <svg-icon icon-class="help" class="mrs" />
      </el-tooltip>
        <span>申请单总额：<b>{{+totalData.all || 0}}</b> SEK</span>
      <el-tooltip
        content="『已完成』状态下的申请单总额"
      >
        <svg-icon icon-class="help" class="mrs" />
      </el-tooltip>
        <span>已完成总额：<b>{{+totalData.allFinishAccount || 0}}</b> SEK</span>
      <el-tooltip
        content="已完成未结算的总额"
      >
        <svg-icon icon-class="help" class="mrs" />
      </el-tooltip>
        <span>已完成未结算：<b>{{+totalData.allFinishUnAccount || 0}}</b> SEK</span>
      <el-tooltip
        content="已完成并且已结算的总额"
      >
        <svg-icon icon-class="help" class="mrs" />
      </el-tooltip>
        <span>已结算：<b>{{+totalData.allAccount || 0}}</b> SEK</span>
      <el-tooltip
        content="除了『已取消』外的未结算总额"
      >
        <svg-icon icon-class="help" class="mrs" />
      </el-tooltip>
        <span>未结算：<b>{{+totalData.allUnAccount || 0}}</b> SEK</span>
    </div>
    <el-form inline :model="listQuery" label-width="100px">
      <el-form-item label="联系人：">
        <el-input v-model="listQuery.conn_user" placeholder="输入联系人/电话/邮箱"></el-input>
      </el-form-item>
      <el-form-item label="受理单号：">
        <el-input v-model="listQuery.order_no" placeholder="输入受理单号"></el-input>
      </el-form-item>
      <el-form-item label="所属使馆：">
        <el-select v-model="listQuery.dipm_id" placeholder="请选择" @change="fetchData(1)">
          <el-option v-for="(item, index) in dipmList" :key="index" :value="item.id" :label="item.name"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="提交时间：">
        <el-date-picker
          v-model="listQuery.created_arr"
          type="daterange"
          align="right"
          value-format="yyyy-MM-dd"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          @change="fetchData(1)"
          :picker-options="pickerOptions">
        </el-date-picker>
      </el-form-item>
      <el-form-item label=" ">
        <el-button @click="fetchData(1)" type="primary">搜索</el-button>
        <el-button @click="onReset" type="default">重置</el-button>
      </el-form-item>
    </el-form>

    <!-- 批量操作区域 -->
    <div class="mbm">
      <el-button type="default" :disabled="!multipleSelection.length" @click="checkoutBatch">批量结算</el-button>
      <el-button type="success" @click="checkoutAll">全部结算</el-button>
    </div>

    <!-- 表格区域  -->
    <el-table
      :data="list"
      v-loading="loading"
      border
      fit
      hightlight-current-row
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55" label="全选" align="center" fixed="left" />
      <el-table-column label="受理单号" width="120" align="center" prop="order_no" />
      <el-table-column label="申请人" width="120" align="center" prop="req_user">
        <template slot-scope="{ row }">
          <span>{{row.req_user || row.conn_user}}</span>
        </template>
      </el-table-column>
      <el-table-column label="联系电话" width="120" align="center" prop="conn_phone" />
      <el-table-column label="联系邮箱" width="200" align="center" prop="conn_email" />
      <el-table-column label="联系地址" min-width="200" prop="to_address" />
      <el-table-column label="订单状态" width="120" align="center">
        <template slot-scope="{ row }">
          <el-tag :type="orderStatusTagType[row.status] || 'info'">{{ row.status_name }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="快递费/状态" width="150" align="center">
        <template slot-scope="{ row }">
          <span>{{ +(row.delivery_fee || 0) }}（{{ row.delivery_pay_status_name }}）</span>
        </template>
      </el-table-column>
      <el-table-column label="使馆费/状态" width="150">
        <template slot-scope="{ row }">
          <span>{{ +(row.dipm_fee || 0) }}（{{ row.dipm_pay_status_name }}）</span>
        </template>
      </el-table-column>
      <el-table-column label="提交时间" width="180" align="center" prop="created_time" />
      <el-table-column label="操作" width="100" align="center" fixed="right">
        <template slot-scope="{ row }">
          <el-popconfirm
            title="您确定要结算该申请单吗？"
            @confirm="checkout(row)"
            >
            <el-link type="primary" slot="reference">结算</el-link>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      :current-page="listQuery.page"
      :page-size="listQuery.page_size"
      :total="total"
      style="padding: 32px 0;"
      layout="total, sizes, prev, pager, next, jumper"
      :page-sizes="[10, 20, 50, 100, 150, 200]"
      @current-change="fetchData"
      @size-change="sizeChange"
    />
  </div>
</template>

<script type="text/javascript">
import type from '@/api/type'
import api from '@/api/order'
import dipm from '@/api/dipm'
import { mapGetters } from 'vuex'
const listQuery = {
  page: 1,
  page_size: 100,
  status: 999,
  delivery_pay_status: 1,
  delivery_fee_status: 0,
  dipm_id: '',
}
export default {
  data() {
    return {
      listQuery: { ...listQuery },
      loading: false,
      list: [],
      total: 0,
      multipleSelection: [],

      orderStatusTagType: { 99: 'danger', 70: 'success' },
      dipmList: [],
      totalData: {},

      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
    }
  },
  computed: {
    selectedDipmFee() {
      let dipm_fee = 0
      this.multipleSelection.map(v => {
        dipm_fee += (+v.dipm_fee || 0)
      })
      return dipm_fee
    }
  },
  created() {
    this.getDipmList()
    this.fetchData()
  },
  methods: {
    sizeChange(page_size) {
      this.listQuery.page_size = page_size
      this.fetchData()
    },
    getDipmList() {
      return dipm.getList({ page_size: 999 })
      .then(res => {
        this.dipmList = res.list || []
      })
    },
    fetchData(page = 1) {
      this.listQuery.page = page
      this.loading = true
      api.getList({
        ...this.listQuery,
      })
      .then(res => {
        this.total = res.total
        this.list = res.list || []
        this.loading = false
      })
      api.deliveryFeeTotal(this.listQuery)
      .then(res => {
        this.totalData = res
      })
    },
    onReset() {
      this.listQuery = { ...listQuery }
      this.fetchData()
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    checkoutBatch() {
      this.$confirm('您确定要把「当前选中」的申请单全部结算吗？', '批量结算', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
      .then(() => {
        api.checkoutDelivery({
          dipm_id: this.listQuery.dipm_id,
          order_list: this.multipleSelection.map(v => v.uuid)
        })
        .then(() => {
          this.$message.success('结算成功')
          this.fetchData()
        })
      })
    },
    checkoutAll() {
      this.$confirm('您确定要把当前筛选条件下的『所有申请单』全部结算吗？', '全部结算', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
      .then(() => {
        api.checkoutDelivery({
          ...this.listQuery
        })
        .then(() => {
          this.$message.success('结算成功')
          this.fetchData()
        })
      })
    },
    checkout(row) {
      api.checkoutDelivery({
        dipm_id: this.listQuery.dipm_id,
        order_list: [ row.uuid ]
      })
      .then(() => {
        this.$message.success('结算成功')
        this.list = this.list.filter(v => v.uuid !== row.uuid)
        this.total -= 1
      })
    },
  }
}
</script>

<style lang="less" scoped>
.total {
  padding: 10px 20px;
  background: #FAFAFA;
  font-size: 14px;
  color: #666;
  border: 1px solid #EBEEF5;
  line-height: 20px;
  &.no-b-line {
    border-bottom: none;
  }
  b {
    color: #ED6900;
  }
  span {
    margin-right: 12px;
  }
}
</style>
